import { useState } from "react";

function News({}) {
    const [news, setNews] = useState([
        {
            icon: "🎉",
            date: "Jul 2024",
            content: "Two papers are accepted to UIST 2024. My first first-author paper is coming out!"
        },
        {
            icon: "🏆",
            date: "April 2024",
            content: "STButton received Jury Honorable Mention Award at CHI 2024!"
        },
        {
            icon: "✈️",
            date: "April 2023",
            content: "Attended CHI 2023 in Hamburg, Germany"
        },
        {
            icon: "🎉",
            date: "Mars 2023",
            content: "One poster is accepted to CHI 2023!"
        },
        {
            icon: "🍀",
            date: "Mars 2023",
            content: "Starting Master at HCI Lab, KAIST"
        }
    ]);
    return (
        <div className="news">
            <div id="title">News</div>
            <div>
                {news.map((n) => {
                    return (
                        <div class="news-content">
                            <span>{n.icon}</span>
                            <span className="date"> {n.date} </span>
                            &nbsp;
                            <span>{n.content}</span>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default News;