function Description(){
    return (
        <div>
        <div id="description">
        I am a master student in School of Computing, KAIST, working with my advisor Prof. Geehyuk Lee in
        &nbsp; <a href = "https://hcil.kaist.ac.kr/" style={{color : "black"}}>HCI Lab</a>.  
        My current research focus is on Human-Computer Interaction (HCI) within physical computing.
        I am interested in expanding naturalistic and seamless interactions, especially by harnessing the dexterity and tactile sensory capabilities of our hands. Recently, I explored the potential of the physical surfaces our hands naturally interact with, transforming them into novel I/O spaces.
        </div>
        </div>
    )
}
export default Description;