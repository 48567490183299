import React, { useEffect } from "react";
import { useState, useRef } from "react";
import "./project.css";

import Popup from "reactjs-popup";
import 'reactjs-popup/dist/index.css';  
import Hex from "./hex";
function Project() {
    const ref = useRef(null);
  const [height, setHeight] = useState("0px");
  const onLoad = () => {
    if (ref.current === null) return;
    setHeight(ref.current.scrollWidth * 9 / 16 + "px");
  };

  useEffect(() => {
    onLoad();
  }, [ref.current])
    const projects = [
        {name : "YOLY",
            subtitle: "You Only Look at Yourself : Real-time Visual and Haptic Guidance System for Golf Swing in Virtual Reality",
            description:"2023 KAIST Graduate School of Culture Technology - Augmented Human",
            category: "Team Project",
            awards : "Best Augmented Humans Project (Runner-up)",
            teaserimg: "yoly.jpg",
            hashtag: ["HW", "Haptic", "VR"],
        youtube: "F4O0P7j7Nj4"},
        {name : "Handgrip",
        subtitle: "Haptic Direction Indicator Grips for Navigating Cyclists",
        description: "2022 School of Computing - Human Computer Interaction",
        category: "Team Project",
        teaserimg: "handgrip.png",
        hashtag: ["HW", "Haptic", "Mobility"],
        youtube : "DaxIBhCXJ5M"},
        {
            name: "Green Bread",
            subtitle: "Bakery-to-Consumer service that allows you to buy bread cheaply at the bakery's closing time",
            description: "KAIST SW Education Center 2nd App Startup Program (2기 App 창업 프로그램)",
            teaserimg: "greenbread.png",
            hashtag: ["SW", "Mobile App"],
            img: "greenbread.png",
            github: "https://github.com/yimjisu/GreenBreadProject",
            youtube: "kJXnlbqIH5w",
            category: "Team Project"
        },
        {name : "VVMarket", 
        subtitle: "Trading second-hand platform for low vision people",
        description:"2022 EPFL - Interaction Design",
        hashtag : ["SW", "Mobile App", "Accessibility"],
        category: "Team Project",
        teaserimg: "vvmarket.png",
        youtube : "uO2BKNnePSo"},
        {name : "Sketch Parrot", 
            subtitle: "Learn how to draw by following good drawing", 
            description:"2021 KAIST Industrial Design - Interactive Software Design", 
            category: "Team Project",
            teaserimg: "sketchparrot.png",
            hashtag : ["SW", "Education"],
            youtube : "GZUo94Nf4eo"},
        {name : "Color Catcher", 
            subtitle : "Playful activity for kids",
            description : "2021 KAIST Industrial Design - Interactive Product Design*",
            teaserimg: "color_catcher.jpg",
            hashtag : ["HW", "Education"],
            youtube : "4_DiDJI3ny0"
        },
        {name : "FolDIY",
         subtitle : "Hanger for tidy closet",
         description : "2021 KAIST Industrial Design - Product Design",
         category: "Team Project",
         teaserimg: "foldiy.png",
        hashtag : ["HW", "Product Design"],
        youtube : "2RgsCyXRFdI"}
    ]

    
    return (
        <div className="wrap">
            {
                projects.map((project) => {
                    return <Popup
                    contentStyle={{width: "80%"}}
                    key = {project.name}
                    trigger = { 
                        <div className = "content">
                            <img src={'/figure/project/'+project.teaserimg}/>
                            <div className = "card-title">
                                <h3>{project.name}</h3>
                                {project.hashtag && <div  class="hashtags">{
                                project.hashtag.map(
                                    (e) => {
                                        if (e == "HW") {
                                            return <p class="hashtag main" >{e}</p>
                                        } 
                                        if (e == "SW") {
                                            return <p class="hashtag sub">{e}</p>
                                        }
                                        return <p class="hashtag other">{e}</p>
                                    }
                                )}</div>}             
                                    
                            </div>
                        </div>
                    } modal nested >
                        {
                            close => (
                                <div className="modal">
                                    <button className="close" onClick={close}>
                                        &times;
                                    </button>
                                    <h2>{project.name}</h2>
                                    <h3 className="modal-header"> {project.subtitle} </h3>
                                    <div className="modal-content">
                                        <p class="modal-description">{project.description}</p>
                                        {
                                            project.category && 
                                            <p class="modal-category">{project.category}</p> 
                                        }
                                        {project.awards && <p class="modal-awards">{project.awards}</p>}
                                    </div>          
                                    {project.hashtag && <p class="modal-hashtag">{"#" + project.hashtag.join(" #")}</p>}             
                                    {
                                        
                                        project.youtube &&
                                        <iframe ref={ref} onLoad={onLoad} height={height} src={"https://www.youtube.com/embed/"+project.youtube+"?autoplay=1&mute=1"}></iframe>
                                        
                                    }
                                    {
                                        project.github && 
                                        
                                        <a href={project.github} class="modal-github">
                                            
                                                    <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="h-4 w-4"
                                                    fill="currentColor"
                                                    viewBox="0 0 24 24">
                                                    <path
                                                        d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" />
                                                    </svg>
                                        </a> 
                                    }
                                    {/* <img className="modal-img" src={ 
                                        project.img ? "figure/project/"+project.img: "figure/project/"+project.teaser}/> */}
                                </div>
                            )
                        }
                    </Popup>
                })
            }
        </div>
    );
}

export default Project;
