import './publication.css';
import Paper from './paper';
function Publication({ref}){
    const papers = [
        {
            name: "Palmrest+: Expanding Laptop Input Space with Shear Force on Palm-Resting Area",
            video : "",
            authors : ['Jisu Yim', 'Seoyeon Bae', 'Taejun Kim', 'Sunbum Kim', 'Geehyuk Lee'],
            where: "UIST 2024",
            img: "palmrest.png",
            pdf: "palmrest.pdf"
        },{
            name: "Pro-Tact: Hierarchical Synthesis of Proprioception and Tactile Exploration for Eyes-Free Ray Pointing on Out-of-View VR Menus",
            video : "",
            authors : ['Yeonsu Kim', 'Jisu Yim', 'Kyunghwan Kim', 'Yohan Yun', 'Geehyuk Lee'],
            where: "UIST 2024",
            img: "protact.png",
            pdf: "protact.pdf"
        },
        {
            name: "STButton: Exploring Opportunities for Buttons with Spatio-Temporal Tactile Output",
            url: "https://dl.acm.org/doi/10.1145/3613905.3648671",
            video : "https://youtu.be/pZJk8ueLfsY?feature=shared",
            authors : ['Yeonsu Kim', 'Jisu Yim', 'Jaehyun Kim', 'Kyunghwan Kim', 'Geehyuk Lee'],
            where: "CHI 2024 Interactivity (Demo)",
            img : "stbutton.png",
            tag : "Demo",
            award : "Jury Honorable Mention Award",
            pdf: "stbutton.pdf"
        },
        {
            name: "HapticPalmrest: Haptic Feedback through the Palm for the Laptop Keyboard",
            url: "https://dl.acm.org/doi/abs/10.1145/3544549.3585663",
            video : "https://youtu.be/RbxXK9G6c5I?feature=shared",
            authors : ['Jisu Yim', 'Sangyoon Lee', 'Geehyuk Lee'],
            where: "CHI 2023 Late-Breaking Work (Poster)",
            img : "hapticpalmrest.png",
            pdf: "hapticpalmrest.pdf"
        },
        {
            name: "CatchLive: Real-time Summarization of Live Streams with Stream Content and Interaction Data",
            url: "https://doi.org/10.1145/3491102.3517461",
            authors : ['Saelyne Yang', 'Jisu Yim', 'Juho Kim', 'Hijung Valentina Shin'],
            where: "CHI 2022",
            page : "https://catchlive.kixlab.org/",
            img : "catchlive.png",
            pdf: "catchlive.pdf"
        },
        {
            name: "SoftVideo: Improving the Learning Experience of Software Tutorial Videos with Collective Interaction Data",
            url: "https://doi.org/10.1145/3490099.3511106",
            authors : ['Saelyne Yang', 'Jisu Yim', 'Aitolkyn Baigutanova', 'Seoyoung Kim', 'Minsuk Chang', 'Juho Kim'],
            where: "IUI 2022",
            page : "https://softvideo.kixlab.org/",
            img : "softvideo.png",
            pdf: "softvideo.pdf"
        }
    ]
    return(
        <div className='publication-container'>
        {
            papers.map((paper) => {
                return (
                    <Paper data={paper} key={paper.name}/>
                )
            })
        }
        </div>
    )
}
export default Publication;